import React, { Suspense, lazy, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import {
  ACCESS_TOKEN,
  FINANCE,
  FORGET_PASSWORD,
  HOME,
  LOGIN,
  RESOURCES,
  SIGNAL_CONTROL,
  SIGNAL_STATUS,
  VIOLATION,
  MAINTENANCE,
  TRAFFIC_ANALYTICS,
  VIDEO,
  VEHICLE_DETAILS,
  VEHICLE_DETAILS_PERMISSION,
  UPDATE_VEHICLE_DETAILS,
  UPDATE_VEHICLE_DETAILS_PERMISSION,
  UPDATE_PERMIT,
  UPDATE_INSURANCE,
  UPDATE_PUCC,
} from "../common/constants";
import Loader from "../common/components/BasicComponents/Loader";
import VehicleDetailContainer from "../containers/vehicleDetails";

/**
 * check if user is logged in, if yes redirect to home page
 * @param {children} children
 * @returns seleted route
 */
const UnProtectedRoute = ({ children }) => {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    return <Navigate to="/home" replace />;
  }
  return children;
};

/**
 * check if user is logged out, if yes redirect to login page
 * @param {children} children
 * @returns seleted route
 */
const ProtectedRoute = ({ children }) => {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

/**
 * used lazy loading for reducing initial loading, bundle size and browser workload.
 */
const LoginRoute = lazy(() => import("../containers/auth"));
const ResetPasswordRoute = lazy(() =>
  import("../components/Auth/ResetPassword")
);
const TrafficViolationRoute = lazy(() =>
  import("../containers/trafficViolation")
);
const MaintenanceRoute = lazy(() => import("../containers/maintenance"));

const HomeRoute = lazy(() => import("../containers/home"));
const FinanceRoute = lazy(() => import("../containers/finance"));
const SignalStatusRoute = lazy(() => import("../containers/signalStatus"));
const Page404Route = lazy(() => import("../components/Auth/Page404"));
const VideoRoute = lazy(() => import("../containers/video"));
const UpdateVehicleDetails = lazy(() =>
  import("../containers/updateVehicleDetails")
);
const UpdatePermit = lazy(() => import("../containers/updatePermit"));
const UpdateInsurance = lazy(() => import("../containers/updateInsurance"));
const UpdatePUCC = lazy(() => import("../containers/updatePUCC"));

const WebRoutes = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = pathname.substring(1);
    /**
     *  Redirect to accessible route
     *  fetch the list of permissions from localhost.
     *  if user don't have permission to specific route redirect to home screen.
     *  else redirect to specific route
     */
    const redirectByPermission = () => {
      const resourceList = JSON.parse(localStorage.getItem(RESOURCES));
      if (!resourceList || resourceList.length === 0) {
        navigate(HOME, { replace: true });
      } else if (currentPath) {
        switch (currentPath) {
          case SIGNAL_STATUS:
            if (!resourceList.includes(SIGNAL_CONTROL)) {
              navigate(HOME, { replace: true });
            }
            break;
          case FINANCE:
            if (!resourceList.includes(FINANCE)) {
              navigate(HOME, { replace: true });
            }
            break;
          case TRAFFIC_ANALYTICS:
            if (!resourceList.includes(VIOLATION)) {
              navigate(HOME, { replace: true });
            }
            break;
          case MAINTENANCE:
            if (!resourceList.includes(MAINTENANCE)) {
              navigate(HOME, { replace: true });
            }
            break;
          case VIDEO:
            if (!resourceList.includes(VIDEO)) {
              navigate(HOME, { replace: true });
            }
            break;
          case VEHICLE_DETAILS:
            if (!resourceList.includes(VEHICLE_DETAILS_PERMISSION)) {
              navigate(HOME, { replace: true });
            }
            break;
          case UPDATE_VEHICLE_DETAILS:
            if (!resourceList.includes(UPDATE_VEHICLE_DETAILS_PERMISSION)) {
              navigate(HOME, { replace: true });
            }
            break;
          case UPDATE_PERMIT:
            if (!resourceList.includes(UPDATE_VEHICLE_DETAILS_PERMISSION)) {
              navigate(HOME, { replace: true });
            }
            break;
          case UPDATE_INSURANCE:
            if (!resourceList.includes(UPDATE_VEHICLE_DETAILS_PERMISSION)) {
              navigate(HOME, { replace: true });
            }
            break;
          case UPDATE_PUCC:
            if (!resourceList.includes(UPDATE_VEHICLE_DETAILS_PERMISSION)) {
              navigate(HOME, { replace: true });
            }
            break;
          default:
            navigate(HOME, { replace: true });
        }
      } else if (resourceList.includes(SIGNAL_CONTROL))
        navigate(SIGNAL_STATUS, { replace: true });
      else if (resourceList.includes(VIOLATION))
        navigate(TRAFFIC_ANALYTICS, { replace: true });
      else if (resourceList.includes(FINANCE))
        navigate(FINANCE, { replace: true });
      else if (resourceList.includes(MAINTENANCE))
        navigate(MAINTENANCE, { replace: true });
      else {
        navigate(HOME, { replace: true });
      }
    };
    if (pathname && localStorage.getItem(ACCESS_TOKEN)) {
      redirectByPermission();
    }
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div>
          <Loader />
        </div>
      }
    >
      <Routes>
        <Route path="" element={<Navigate to="/home" />} />
        <Route
          exact
          path={LOGIN}
          element={
            <UnProtectedRoute>
              <LoginRoute />
            </UnProtectedRoute>
          }
        />
        <Route
          exact
          path={FORGET_PASSWORD}
          element={
            <UnProtectedRoute>
              <ResetPasswordRoute />
            </UnProtectedRoute>
          }
        />
        <Route
          exact
          path={HOME}
          element={
            <ProtectedRoute>
              <HomeRoute />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={FINANCE}
          element={
            <ProtectedRoute>
              <FinanceRoute />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={SIGNAL_STATUS}
          element={
            <ProtectedRoute>
              <SignalStatusRoute />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={TRAFFIC_ANALYTICS}
          element={
            <ProtectedRoute>
              <TrafficViolationRoute />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={MAINTENANCE}
          element={
            <ProtectedRoute>
              <MaintenanceRoute />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={VIDEO}
          element={
            <ProtectedRoute>
              <VideoRoute />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={VEHICLE_DETAILS}
          element={
            <ProtectedRoute>
              <VehicleDetailContainer />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={UPDATE_VEHICLE_DETAILS}
          element={
            <ProtectedRoute>
              <UpdateVehicleDetails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={UPDATE_PERMIT}
          element={
            <ProtectedRoute>
              <UpdatePermit />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={UPDATE_INSURANCE}
          element={
            <ProtectedRoute>
              <UpdateInsurance />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={UPDATE_PUCC}
          element={
            <ProtectedRoute>
              <UpdatePUCC />
            </ProtectedRoute>
          }
        />

        <Route exect path="404" element={<Page404Route />} />
        <Route path="*" element={<Navigate to="404" />} />
      </Routes>
    </Suspense>
  );
};

export default WebRoutes;

UnProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
